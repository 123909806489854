$( document ).ready(function(){
    var nav = $(".wk-nav__mainnav"),
        navParent = nav.find(".wk-nav__mainnav-elem"),
        navSubSub = nav.find(".wk-nav__mainnav-subsubnav"),
        navLink = nav.find(".wk-nav__mainnav-elem_link");
        navHoverDiv = nav.find(".wk-nav__mainnav-elem-hover");
        navChild = nav.find(".wk-nav__mainnav-subnav");
        navTitleElem = nav.find(".wk-nav__mainnav-elem_title");
        navTitle = nav.find(".wk-nav__mainnav-subelem_title");
        navSubParent = nav.find(".wk-nav__mainnav-subelem");

    function navHover() {
        navParent.on({
            mouseenter: function(event) {
                console.log("drin")
                event.preventDefault();
                $(this).children("a").addClass("active");
                $(this).children(".wk-nav__mainnav-elem_title").addClass("active");
                $(this).children(".wk-nav__mainnav-elem-hover").addClass("active");
                $(this).children(".wk-nav__mainnav-elem-hover").children(".wk-nav__mainnav-subnav").addClass("active");
            },
            mouseleave: function() {
                console.log("raus")
                $(this).children("a").removeClass("active");
                $(this).children(".wk-nav__mainnav-elem_title").removeClass("active");
                $(this).children(".wk-nav__mainnav-elem-hover").removeClass("active");
                $(this).children(".wk-nav__mainnav-elem-hover").children(".wk-nav__mainnav-subnav").removeClass("active");
            }
        });
        navTitle.on({
            mouseenter: function(event) {
                console.log("drin1")
                $(this).children(".wk-nav__mainnav-subsubnav").show();
            },
            mouseleave: function() {
                $(this).children(".wk-nav__mainnav-subsubnav").hide();
            }
        });

        navSubParent.on({
            mouseenter: function(event) {
                event.preventDefault();
                $(this).children("a").addClass("active");
                $(this).children(".wk-nav__mainnav-subsubnav").addClass("active");
            },
            mouseleave: function() {
                $(this).children("a").removeClass("active");
                navSubSub.removeClass("active");
            }
        });
    } 
    
    function navHover2() {
        navParent.on({
            mouseenter: function(event) {
                console.log("drin")
                event.preventDefault();
                $(this).children("a").addClass("active");
                $(this).children(".wk-nav__mainnav-elem_title").addClass("active");
                $(this).children(".wk-nav__mainnav-elem-hover").addClass("active");
                $(this).children(".wk-nav__mainnav-elem-hover").children(".wk-nav__mainnav-subnav").addClass("active");
            },
            mouseleave: function() {
                $(this).children("a").removeClass("active");
                $(this).children(".wk-nav__mainnav-elem_title").removeClass("active");
                navChild.removeClass("active");
                navHoverDiv.removeClass("active");
            }
        });
        navTitle.on({
            click: function(event) {
                if(!$(this).hasClass("active")) {
                    console.log($(this));
                    $(this).addClass("active");
                    $(this).parent().children(".wk-nav__mainnav-subsubnav").addClass("active");
                } else {
                    $(this).removeClass("active");
                    navSubSub.removeClass("active");
                }
            }
        });

        navSubParent.on({
            // mouseenter: function(event) {
            //     event.preventDefault();
            //     $(this).children("a").addClass("active");
            //     $(this).children(".wk-nav__mainnav-subsubnav").addClass("active");
            // },
            // mouseleave: function() {
            //     $(this).children("a").removeClass("active");
            //     navSubSub.removeClass("active");
            // }
        });
    }

    if($(window).width() >= 1024){
        navHover();
    } else {
        navHover2();
    }


    function navClick() {
        navParent.children('a').on('click', function(event) {
            // event.preventDefault();
        });
        
        navLink.on({
            click: function(event) {
                event.preventDefault();
                if(!$(this).hasClass("active")) {
                    navLink.removeClass("active");
                    $(this).addClass("active");
                } else {
                    navLink.removeClass("active");
                }
            }
        });
        navTitle.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {
                    navTitle.parent().removeClass("active");
                    $(this).parent().addClass("active");
                } else {
                    navTitle.parent().removeClass("active");
                }
            }
        });

        navParent.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {
                    console.log("drin")
                    console.log($(this))
                    event.preventDefault();
                    $(this).addClass("active");
                    $(this).children(".wk-nav__mainnav-elem_title").addClass("active");
                    $(this).children(".wk-nav__mainnav-elem-hover").addClass("active");
                    $(this).children(".wk-nav__mainnav-elem-hover").children(".wk-nav__mainnav-subnav").addClass("active");
                } else {
                    $(this).children("a").removeClass("active");
                    $(this).children(".wk-nav__mainnav-elem_title").removeClass("active");
                    navChild.removeClass("active");
                    navHoverDiv.removeClass("active");
                }
                
            }
        });
        navTitle.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {

                } else {
                }
            }
        });

        navSubParent.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {
                    event.preventDefault();
                    $(this).children("a").addClass("active");
                    $(this).children(".wk-nav__mainnav-subsubnav").addClass("active");

                } else {
                    $(this).children("a").removeClass("active");
                    navSubSub.removeClass("active");
                }
                
            }
        });
    }
})
$( document ).ready(function(){
    var nav = $(".wk-nav__burgernav"),
        navParent = nav.find(".wk-nav__burgernav-elem"),
        navSubSub = nav.find(".wk-nav__burgernav-subsubnav"),
        navLink = nav.find(".wk-nav__burgernav-elem_link");
        navHoverDiv = nav.find(".wk-nav__burgernav-elem-hover");
        navChild = nav.find(".wk-nav__burgernav-subnav");
        navTitleElem = nav.find(".wk-nav__burgernav-elem_title");
        navTitle = nav.find(".wk-nav__burgernav-subelem_title");
        navSubParent = nav.find(".wk-nav__burgernav-subelem");

    function navHover() {
        navParent.on({
            mouseenter: function(event) {
                console.log("drin")
                event.preventDefault();
                $(this).children("a").addClass("active");
                $(this).children(".wk-nav__burgernav-elem_title").addClass("active");
                $(this).children(".wk-nav__burgernav-elem-hover").addClass("active");
                $(this).children(".wk-nav__burgernav-elem-hover").children(".wk-nav__burgernav-subnav").addClass("active");
            },
            mouseleave: function() {
                $(this).children("a").removeClass("active");
                $(this).children(".wk-nav__burgernav-elem_title").removeClass("active");
                navChild.removeClass("active");
                navHoverDiv.removeClass("active");
            }
        });
        navTitle.on({
            mouseenter: function(event) {
                console.log("drin1")
                $(this).children(".wk-nav__burgernav-subsubnav").show();
            },
            mouseleave: function() {
                $(this).children(".wk-nav__burgernav-subsubnav").hide();
            }
        });

        navSubParent.on({
            mouseenter: function(event) {
                event.preventDefault();
                $(this).children("a").addClass("active");
                $(this).children(".wk-nav__burgernav-subsubnav").addClass("active");
            },
            mouseleave: function() {
                $(this).children("a").removeClass("active");
                navSubSub.removeClass("active");
            }
        });
    } 
    
    function navHover2() {
        navParent.on({
            mouseenter: function(event) {
                console.log("drin")
                event.preventDefault();
                $(this).children("a").addClass("active");
                $(this).children(".wk-nav__burgernav-elem_title").addClass("active");
                $(this).children(".wk-nav__burgernav-elem-hover").addClass("active");
                $(this).children(".wk-nav__burgernav-elem-hover").children(".wk-nav__burgernav-subnav").addClass("active");
            },
            mouseleave: function() {
                $(this).children("a").removeClass("active");
                $(this).children(".wk-nav__burgernav-elem_title").removeClass("active");
                navChild.removeClass("active");
                navHoverDiv.removeClass("active");
            }
        });
        navTitle.on({
            click: function(event) {
                if(!$(this).hasClass("active")) {
                    console.log($(this));
                    $(this).addClass("active");
                    $(this).parent().children(".wk-nav__burgernav-subsubnav").addClass("active");
                } else {
                    $(this).removeClass("active");
                    navSubSub.removeClass("active");
                }
            }
        });

        navSubParent.on({
            // mouseenter: function(event) {
            //     event.preventDefault();
            //     $(this).children("a").addClass("active");
            //     $(this).children(".wk-nav__burgernav-subsubnav").addClass("active");
            // },
            // mouseleave: function() {
            //     $(this).children("a").removeClass("active");
            //     navSubSub.removeClass("active");
            // }
        });
    }

    if($(window).width() >= 1024){
        navHover();
    } else {
        navHover2();
    }


    function navClick() {
        navParent.children('a').on('click', function(event) {
            // event.preventDefault();
        });
        
        navLink.on({
            click: function(event) {
                event.preventDefault();
                if(!$(this).hasClass("active")) {
                    navLink.removeClass("active");
                    $(this).addClass("active");
                } else {
                    navLink.removeClass("active");
                }
            }
        });
        navTitle.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {
                    navTitle.parent().removeClass("active");
                    $(this).parent().addClass("active");
                } else {
                    navTitle.parent().removeClass("active");
                }
            }
        });

        navParent.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {
                    console.log("drin")
                    console.log($(this))
                    event.preventDefault();
                    $(this).addClass("active");
                    $(this).children(".wk-nav__burgernav-elem_title").addClass("active");
                    $(this).children(".wk-nav__burgernav-elem-hover").addClass("active");
                    $(this).children(".wk-nav__burgernav-elem-hover").children(".wk-nav__burgernav-subnav").addClass("active");
                } else {
                    $(this).children("a").removeClass("active");
                    $(this).children(".wk-nav__burgernav-elem_title").removeClass("active");
                    navChild.removeClass("active");
                    navHoverDiv.removeClass("active");
                }
                
            }
        });
        navTitle.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {

                } else {
                }
            }
        });

        navSubParent.on({
            click: function(event) {
                if(!$(this).parent().hasClass("active")) {
                    event.preventDefault();
                    $(this).children("a").addClass("active");
                    $(this).children(".wk-nav__burgernav-subsubnav").addClass("active");

                } else {
                    $(this).children("a").removeClass("active");
                    navSubSub.removeClass("active");
                }
                
            }
        });
    }
})